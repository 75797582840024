function Footer() {
  return (
    <div className="broder-t flex items-center justify-center gap-1 border-t-2 bg-[#736BC2]/[0.90] px-3 py-4 text-white">
      <a
        href="http://blockchainconsultora.com"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://blockchainconsultora.com/_next/image?url=%2Fimages%2Fwhite-logo.png&w=256&q=75"
          className="h-4"
        />
      </a>
      <p className="text-black/60 transition-all duration-700 hover:underline">
        {' '}
        - Especialistas en sistemas blockchain
      </p>
    </div>
  )
}

export { Footer }
